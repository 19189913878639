<template>
  <div class="row">
    <div class="col-lg-7">
      <div class="card">
        <div class="card-block">
          <div class="h4 m-0">{{ $t('general.step1') }}</div>
          <small class="text-muted">{{ $t('general.basicParameters') }}</small>
          <div class="form-group mt-1">
            <span class="h6 setting-title">{{ $t('firewall.firewallSettings') }}</span>
            <label for="name">{{ $t('general.name') }}</label>
            <input
              type="text"
              name="name"
              id="name"
              :placeholder="$t('firewall.firewallNamePlaceHolder')"
              class="form-control"
              v-validate="'required|max:32'"
              :class="{ input: true, 'is-danger': errors.has('name') }"
              v-model="newFirewallData.name"
            />
            <span v-show="errors.has('name')" class="help is-danger">{{ errors.first('name') }}</span>
          </div>

          <div class="form-group" v-if="areLocationsVisible">
            <label for="base_location">{{ $t('aps.location') }}</label>
            <select
              v-model="newFirewallData.base_location"
              name="location"
              class="form-control"
              id="base_location"
            >
              <option v-for="location in locationsList" :key="location.id" :value="location.id">
                {{ location.name }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label for="policy">{{ $t('firewall.policy') }}</label>
            <select
              v-model="newFirewallData.policy"
              :value="newFirewallData.policy"
              name="location"
              class="form-control"
              id="policy"
            >
              <option>ACCEPT</option>
              <option>DROP</option>
              <option>RETURN</option>
            </select>
          </div>

          <div class="form-group">
            <label for="direction">{{ $t('firewall.direction') }}</label>
            <select
              v-model="newFirewallData.direction"
              :value="newFirewallData.direction"
              name="location"
              class="form-control"
              id="direction"
            >
<!--              <option>ANY</option>-->
<!--              <option>IN</option>-->
<!--              <option>OUT</option>-->
              <option v-for="directionType of directionTypes" :value="directionType">
                {{getDirectionTypeWithI18n(directionType)}}
              </option>
            </select>
          </div>

          <Alert
            v-if="showingFirewallInfo"
            closable
            class="alert-info animated fadeIn"
            @alert-closed="showingFirewallInfo = false"
          >
            <template #text>
              <span class="mr-h">
                {{ $t('firewall.infoMessage') }}:
                <a href="https://wiki.archlinux.org/index.php/Iptables">
                  https://wiki.archlinux.org/index.php/Iptables
                </a>
              </span>
            </template>
          </Alert>

          <div class="mt-2">
            <WButton primary outline
              customClass="cpe-register-next-button float-right"
              @click="setNewFirewallData"
              :disabled="errors.any()"
            >
              {{ $t('general.next') }}
            </WButton>
            <WButton secondary outline
              customClass="cpe-register-back-button float-left"
              @click="$parent.prevStep"
            >
              {{ $t('general.back') }}
            </WButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commonService from '../../services/commonService';
import helpers from '../../helpers';
import Alert from '../../components/Universal/alert/alert.vue';

const DIRECTION_TYPES = ['ANY', 'IN', 'OUT'];

export default {
  components: { Alert },
  name: 'FirewallStep1',
  data() {
    return {
      showingFirewallInfo: true,
      newFirewallData: {
        name: '',
        policy: 'ACCEPT',
        direction: 'ANY'
      }
    };
  },
  computed: {
    directionTypes() {
      return DIRECTION_TYPES;
    },
    locationsList() {
      return this.$store.getters.locationsListSorted;
    }
  },
  methods: {
    getDirectionTypeWithI18n(typeName) {
      if (this.$t(`firewall.${typeName}`)) {
        return this.$t(`firewall.${typeName}`)
      } else {
        return typeName
      }
    },
    setNewFirewallData() {
      this.$validator
        .validateAll({
          name: this.newFirewallData.name
        })
        .then(
          (result) => {
            if (result) {
              this.$store.commit('setNewFirewallData', this.newFirewallData);
              this.$parent.nextStep();
            }
          },
          () => {
            console.warn('Validation failed');
          }
        );
    }
  },
  created() {
    this.newFirewallData = JSON.parse(JSON.stringify(this.$store.state.newFirewallData));
    if (this.$store.state.userData.base_location) {
      this.newFirewallData.base_location = this.$store.state.userData.base_location
    }
  }
};
</script>
<style lang="scss" scoped></style>
