<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-block" name="top">
          <div class="h4 mb-2">{{ $t('general.step2') }}</div>
          <div class="row">
            <div class="rules col-lg-12">
              <div
                class="card animated fadeIn fadeOut"
                @click="toggleEdit"
                v-if="newFirewallData.rules.length"
                :data-index="index"
                v-for="(rule, index) in newFirewallData.rules"
                :class="{
                  'border-secondary': rule.jump === 'RETURN',
                  'border-danger': rule.jump === 'DROP',
                  'border-success': rule.jump === 'ACCEPT',
                  'text-white': index === ruleIndexForEdit,
                  'bg-success': rule.jump === 'ACCEPT' && index === ruleIndexForEdit,
                  'bg-danger': rule.jump === 'DROP' && index === ruleIndexForEdit
                }"
              >
                <div class="card-body">
                  <div class="h1 text-right mb-4">
                    <small class="text-muted mr-2"># {{ index + 1 }}</small>
                    <!--<i class="icon-check text-success" v-if="rule.jump === 'ACCEPT'"></i>-->
                    <!--<i class="icon-close text-danger" v-if="rule.jump === 'DROP'"></i>-->
                  </div>
                  <div class="h4 mb-0">
                    <span v-if="rule.jump">{{ rule.jump }}</span>
                    <span v-else>RETURN</span>
                  </div>
                  <small class="text-uppercase font-weight-bold mr-1">
                    {{ $t('firewall.ruleProtocol') }}:
                    <span v-if="rule.protocol" class="text-muted">{{ rule.protocol }}</span>
                    <span class="text-muted" v-else>—</span>
                  </small>
                  <small class="text-uppercase font-weight-bold">
                    {{ $t('firewall.ruleIPProtocol') }}:
                    <span v-if="rule.ip_protocol" class="text-muted">{{ rule.ip_protocol }}</span>
                    <span class="text-muted" v-else>—</span>
                  </small>
                  <div class="row" v-if="rule.destination.length || rule.source.length">
                    <div class="col-lg-6">
                      <div class="h6 mb-q mt-1">{{ $t('firewall.fromMAC') }}</div>
                      <small
                        v-if="rule.source.length"
                        class="text-muted text-uppercase font-weight-bold rule-value"
                        v-for="mac in rule.source"
                      >
                        {{ mac }}
                      </small>
                      <small v-if="!rule.source.length" class="text-muted text-uppercase font-weight-bold rule-value">
                        -
                      </small>
                    </div>
                    <div class="col-lg-6">
                      <div class="h6 mb-q mt-1">{{ $t('firewall.toMAC') }}</div>
                      <small
                        v-if="rule.destination.length"
                        class="text-muted text-uppercase font-weight-bold rule-value"
                        v-for="mac in rule.destination"
                      >
                        {{ mac }}
                      </small>
                      <small
                        v-if="!rule.destination.length"
                        class="text-muted text-uppercase font-weight-bold rule-value"
                      >
                        -
                      </small>
                    </div>
                  </div>
                  <div
                    class="row"
                    v-if="rule.protocol === 'ipv4' && (rule.ip_destination.length || rule.ip_source.length)"
                  >
                    <div class="col-lg-6">
                      <div class="h6 mb-q mt-1">{{ $t('firewall.fromIP') }}</div>
                      <small
                        v-if="rule.ip_source.length"
                        class="text-muted text-uppercase font-weight-bold rule-value"
                        v-for="ip in rule.ip_source"
                      >
                        {{ ip }}
                      </small>
                      <small
                        v-if="!rule.ip_source.length"
                        class="text-muted text-uppercase font-weight-bold rule-value"
                      >
                        -
                      </small>
                    </div>
                    <div class="col-lg-6">
                      <div class="h6 mb-q mt-1">{{ $t('firewall.toIP') }}</div>
                      <small
                        v-if="rule.ip_destination.length"
                        class="text-muted text-uppercase font-weight-bold rule-value"
                        v-for="ip in rule.ip_destination"
                      >
                        {{ ip }}
                      </small>
                      <small
                        v-if="!rule.ip_destination.length"
                        class="text-muted text-uppercase font-weight-bold rule-value"
                      >
                        -
                      </small>
                    </div>
                  </div>
                  <div
                    class="row"
                    v-if="
                      (rule.ip_protocol === 'tcp' || rule.ip_protocol === 'udp') &&
                      (rule.ip_destination_port.length || rule.ip_source_port.length)
                    "
                  >
                    <div class="col-lg-6">
                      <div class="h6 mb-q mt-1">{{ $t('firewall.fromPort') }}</div>
                      <small
                        v-if="rule.ip_source_port.length"
                        class="text-muted text-uppercase font-weight-bold rule-value"
                        v-for="port in rule.ip_source_port"
                      >
                        {{ port }}
                      </small>
                      <small
                        v-if="!rule.ip_source_port.length"
                        class="text-muted text-uppercase font-weight-bold rule-value"
                      >
                        -
                      </small>
                    </div>
                    <div class="col-lg-6">
                      <div class="h6 mb-q mt-1">{{ $t('firewall.toPort') }}</div>
                      <small
                        v-if="rule.ip_destination_port.length"
                        class="text-muted text-uppercase font-weight-bold rule-value"
                        v-for="port in rule.ip_destination_port"
                      >
                        {{ port }}
                      </small>
                      <small
                        v-if="!rule.ip_destination_port.length"
                        class="text-muted text-uppercase font-weight-bold rule-value"
                      >
                        -
                      </small>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card animated fadeIn fadeOut border-secondary rule" @click="editRuleModal = true">
                <div class="card-body">
                  <div class="h1 text-right mb-4">
                    <i class="glyphicons glyphicons-plus text-muted"></i>
                    <!--<i class="icon-close text-danger" v-if="rule.jump === 'DROP'"></i>-->
                  </div>
                  <div class="rule-title">
                    <div class="h4 text-muted text-uppercase">{{ $t('firewall.AddRule') }}</div>
                    <small class="text-muted text-uppercase font-weight-bold">
                      {{ $t('firewall.AddRule') }} #{{ newFirewallData.rules.length + 1 }}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-2">
            <WButton primary outline
              customClass="cpe-register-next-button float-right"
              @click="setNewFirewallData"
              :disabled="errors.any()"
            >
              {{ $t('general.next') }}
            </WButton>
            <WButton secondary outline
              customClass="cpe-register-back-button float-left"
              @click="$parent.prevStep"
            >
              {{ $t('general.back') }}
            </WButton>
          </div>
        </div>
      </div>
    </div>
    <modal
      title="Rule settings"
      large
      @cancel="cancelRule"
      v-model="editRuleModal"
      class="modal-success"
      effect="fade/zoom"
    >
      <h4 slot="modal-title" class="modal-title">{{ $t('firewall.RuleSettings') }}</h4>

      <div class="">
        <div class="h5 m-0" v-if="ruleIndexForEdit || ruleIndexForEdit === 0">
          {{ $t('firewall.editRule') }} #{{ ruleIndexForEdit + 1 }}
        </div>
        <div class="h5 m-0" v-else>{{ $t('firewall.addNewRule') }}</div>
        <div class="h6 font-weight-bold m-0 mt-2">{{ $t('firewall.Linklayer') }}</div>
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group mt-1">
              <label for="source">{{ $t('firewall.fromMACaddress') }}</label>
              <div class="input-group">
                <input
                  type="text"
                  name="source"
                  id="source"
                  :placeholder="$t('firewall.enterMACPlaceholder')"
                  class="form-control"
                  v-validate="'mac'"
                  @keyup.13="addValue('source')"
                  :class="{ input: true, 'is-danger': errors.has('source') }"
                  v-model="newSource"
                />
                <span class="input-group-btn">
                  <WButton success
                    @click="addValue('source')"
                    :disabled="newSource === ''"
                  >
                    <i class="fa fa-plus"></i>
                  </WButton>
                </span>
                <span class="input-group-btn">
                  <WButton warning
                    customClass="ml-q"
                    @click="addValue('source', true)"
                    :disabled="newSource === ''"
                  >
                    <i class="fa fa-exclamation"></i>
                    <i class="fa fa-plus"></i>
                  </WButton>
                </span>
              </div>
              <span v-show="errors.has('source')" class="help is-danger">{{ errors.first('source') }}</span>
              <a
                class="text-muted mr-1 l2-value"
                v-for="(mac, index) in newRule.source"
                :class="{ 'text-warning': mac[0] === '!' }"
                :data-id="index"
                data-type="source"
                @click="removeValue"
              >
                {{ mac.toUpperCase() }}
                <i class="fa fa-remove text-danger"></i>
              </a>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group mt-1">
              <label for="destination">{{ $t('firewall.toMACaddress') }}</label>
              <div class="input-group">
                <input
                  type="text"
                  name="destination"
                  id="destination"
                  :placeholder="$t('firewall.enterMACPlaceholder')"
                  class="form-control"
                  v-validate="'mac'"
                  @keyup.13="addValue('destination')"
                  :class="{ input: true, 'is-danger': errors.has('destination') }"
                  v-model="newDestination"
                />
                <span class="input-group-btn">
                  <WButton success
                    @click="addValue('destination')"
                    :disabled="newDestination === ''"
                  >
                    <i class="fa fa-plus"></i>
                  </WButton>
                </span>
                <span class="input-group-btn">
                  <WButton warning
                    customClass="ml-q"
                    @click="addValue('destination', true)"
                    :disabled="newDestination === ''"
                  >
                    <i class="fa fa-exclamation"></i>
                    <i class="fa fa-plus"></i>
                  </WButton>
                </span>
              </div>
              <span v-show="errors.has('destination')" class="help is-danger">{{ errors.first('destination') }}</span>
              <a
                class="text-muted mr-1 l2-value"
                v-for="(mac, index) in newRule.destination"
                :class="{ 'text-warning': mac[0] === '!' }"
                :data-id="index"
                data-type="destination"
                @click="removeValue"
              >
                {{ mac.toUpperCase() }}
                <i class="fa fa-remove text-danger"></i>
              </a>
            </div>
          </div>
        </div>

        <div class="h6 m-0 mt-2 font-weight-bold">{{ $t('firewall.Internetlayer') }}</div>
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group mt-1">
              <label for="protocol">{{ $t('firewall.ruleProtocol') }}</label>
              <select
                v-model="newRule.protocol"
                :value="newRule.protocol"
                name="protocol"
                class="form-control"
                id="protocol"
              >
                <option :value="''">—</option>
                <option>ipv4</option>
                <option>ether-arp</option>
              </select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <div class="form-group mt-1">
              <label for="ip-source">{{ $t('firewall.fromIPaddress') }}</label>
              <div class="input-group">
                <input
                  type="text"
                  name="ip-source"
                  id="ip-source"
                  :placeholder="$t('firewall.enterIPPlaceholder')"
                  class="form-control"
                  @keyup.13="addValue('ip_source')"
                  :disabled="newRule.protocol !== 'ipv4'"
                  v-validate="'ipv4mask'"
                  :class="{ input: true, 'is-danger': errors.has('ip-source') }"
                  v-model="newIpSource"
                />
                <span class="input-group-btn">
                  <WButton success
                    @click="addValue('ip_source')"
                    :disabled="newIpSource === ''"
                  >
                    <i class="fa fa-plus"></i>
                  </WButton>
                </span>
                <span class="input-group-btn">
                  <WButton warning
                    customClass="ml-q"
                    @click="addValue('ip_source', true)"
                    :disabled="newIpSource === ''"
                  >
                    <i class="fa fa-exclamation"></i>
                    <i class="fa fa-plus"></i>
                  </WButton>
                </span>
              </div>
              <span v-show="errors.has('ip-source')" class="help is-danger">{{ errors.first('ip-source') }}</span>
              <a
                class="text-muted mr-1 l2-value"
                v-if="newRule.protocol === 'ipv4'"
                v-for="(ip, index) in newRule.ip_source"
                :class="{ 'text-warning': ip[0] === '!' }"
                :data-id="index"
                data-type="ip_source"
                @click="removeValue"
              >
                {{ ip }}
                <i class="fa fa-remove text-danger"></i>
              </a>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group mt-1">
              <label for="ip-destination">{{ $t('firewall.toIPaddress') }}</label>
              <div class="input-group">
                <input
                  type="text"
                  name="ip-destination"
                  id="ip-destination"
                  :placeholder="$t('firewall.enterIPPlaceholder')"
                  class="form-control"
                  v-validate="'ipv4mask'"
                  :disabled="newRule.protocol !== 'ipv4'"
                  @keyup.13="addValue('ip_destination')"
                  :class="{ input: true, 'is-danger': errors.has('ip-destination') }"
                  v-model="newIpDestination"
                />
                <span class="input-group-btn">
                  <WButton success
                    @click="addValue('ip_destination')"
                    :disabled="newIpDestination === ''"
                  >
                    <i class="fa fa-plus"></i>
                  </WButton>
                </span>
                <span class="input-group-btn">
                  <WButton warning
                    customClass="ml-q"
                    @click="addValue('ip_destination', true)"
                    :disabled="newIpDestination === ''"
                  >
                    <i class="fa fa-exclamation"></i>
                    <i class="fa fa-plus"></i>
                  </WButton>
                </span>
              </div>
              <span v-show="errors.has('ip-destination')" class="help is-danger">
                {{ errors.first('ip-destination') }}
              </span>
              <a
                class="text-muted mr-1 l2-value"
                v-if="newRule.protocol === 'ipv4'"
                :class="{ 'text-warning': ip[0] === '!' }"
                v-for="(ip, index) in newRule.ip_destination"
                :data-id="index"
                data-type="ip_destination"
                @click="removeValue"
              >
                {{ ip }}
                <i class="fa fa-remove text-danger"></i>
              </a>
            </div>
          </div>
        </div>

        <div class="h6 m-0 mt-2 font-weight-bold">{{ $t('firewall.Transportlayer') }}</div>
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group mt-1">
              <label for="ip_protocol">{{ $t('firewall.ruleIPProtocol') }}</label>
              <select
                v-model="newRule.ip_protocol"
                :disabled="newRule.protocol !== 'ipv4'"
                :class="{ 'select-disabled': newRule.protocol !== 'ipv4' }"
                :value="newRule.ip_protocol"
                name="ip_protocol"
                class="form-control"
                id="ip_protocol"
              >
                <option :value="''">—</option>
                <option>tcp</option>
                <option>udp</option>
                <option>icmp</option>
                <option>dccp</option>
                <option>sctp</option>
                <option>l2tp</option>
                <option>esp</option>
              </select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <div class="form-group mt-1">
              <label for="ip-source">{{ $t('firewall.fromPortModal') }}</label>
              <div class="input-group">
                <input
                  type="text"
                  name="port-source"
                  id="port-source"
                  :placeholder="$t('firewall.enterPortPlaceholder')"
                  class="form-control"
                  v-validate="'numeric|between:1,65536'"
                  @keyup.13="addValue('ip_source_port')"
                  :disabled="!(newRule.ip_protocol === 'tcp' || newRule.ip_protocol === 'udp')"
                  :class="{ input: true, 'is-danger': errors.has('port-source') }"
                  v-model="newPortSource"
                />
                <span class="input-group-btn">
                  <WButton success
                    @click="addValue('ip_source_port')"
                    :disabled="newPortSource === ''"
                  >
                    <i class="fa fa-plus"></i>
                  </WButton>
                </span>
                <span class="input-group-btn">
                  <WButton warning
                    customClass="ml-q"
                    @click="addValue('ip_source_port', true)"
                    :disabled="newPortSource === ''"
                  >
                    <i class="fa fa-exclamation"></i>
                    <i class="fa fa-plus"></i>
                  </WButton>
                </span>
              </div>
              <span v-show="errors.has('port-source')" class="help is-danger">{{ errors.first('port-source') }}</span>
              <a
                class="text-muted mr-1 l2-value"
                v-if="newRule.ip_protocol === 'tcp' || newRule.ip_protocol === 'udp'"
                v-for="(port, index) in newRule.ip_source_port"
                :class="{ 'text-warning': port[0] === '!' }"
                :data-id="index"
                data-type="ip_source_port"
                @click="removeValue"
              >
                {{ port }}
                <i class="fa fa-remove text-danger"></i>
              </a>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group mt-1">
              <label for="ip-destination">{{ $t('firewall.toPortModal') }}</label>
              <div class="input-group">
                <input
                  type="text"
                  name="port-destination"
                  id="port-destination"
                  :placeholder="$t('firewall.enterPortPlaceholder')"
                  class="form-control"
                  @keyup.13="addValue('ip_destination_port')"
                  v-validate="'numeric|between:1,65536'"
                  :disabled="!(newRule.ip_protocol === 'tcp' || newRule.ip_protocol === 'udp')"
                  :class="{ input: true, 'is-danger': errors.has('port-destination') }"
                  v-model="newPortDestination"
                />
                <span class="input-group-btn">
                  <WButton success
                    @click="addValue('ip_destination_port')"
                    :disabled="newPortDestination === ''"
                  >
                    <i class="fa fa-plus"></i>
                  </WButton>
                </span>
                <span class="input-group-btn">
                  <WButton warning
                    customClass="ml-q"
                    @click="addValue('ip_destination_port', true)"
                    :disabled="newPortDestination === ''"
                  >
                    <i class="fa fa-exclamation"></i>
                    <i class="fa fa-plus"></i>
                  </WButton>
                </span>
              </div>
              <span v-show="errors.has('port-destination')" class="help is-danger">
                {{ errors.first('port-destination') }}
              </span>
              <a
                class="text-muted mr-1 l2-value"
                v-for="(port, index) in newRule.ip_destination_port"
                :class="{ 'text-warning': port[0] === '!' }"
                v-if="newRule.ip_protocol === 'tcp' || newRule.ip_protocol === 'udp'"
                :data-id="index"
                data-type="ip_destination_port"
                @click="removeValue"
              >
                {{ port }}
                <i class="fa fa-remove text-danger"></i>
              </a>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <div class="form-group mt-1">
              <label for="jump">{{ $t('firewall.Action') }}</label>
              <select v-model="newRule.jump" :value="newRule.jump" name="location" class="form-control" id="jump">
                <option>ACCEPT</option>
                <option>DROP</option>
                <option>RETURN</option>
              </select>
            </div>
          </div>
          <div class="col-lg-4"></div>
        </div>
      </div>

      <div slot="modal-footer" class="modal-footer">
        <div class="col-lg-4">
          <WButton success outline fullWidth
            v-if="ruleIndexForEdit === false"
            @click="pushNewRule"
            :disabled="errors.any()"
          >
            {{ $t('firewall.AddRule') }}
          </WButton>
          <WButton success outline fullWidth
            v-if="ruleIndexForEdit || ruleIndexForEdit === 0"
            @click="pushNewRule"
            :disabled="errors.any()"
          >
            {{ $t('firewall.SaveRule') }} #{{ ruleIndexForEdit + 1 }}
          </WButton>
        </div>
        <div class="col-lg-4">
          <WButton secondary outline fullWidth
            @click="cancelRule"
          >
            {{ $t('general.cancel') }}
          </WButton>
        </div>
        <div class="col-lg-4">
          <WButton danger outline fullWidth
            v-if="ruleIndexForEdit || ruleIndexForEdit === 0"
            @click="deleteRule"
          >
            {{ $t('firewall.deleteRuleButton') }} #{{ ruleIndexForEdit + 1 }}
          </WButton>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from '../../components/Modal.vue';
import commonService from '../../services/commonService';
import helpers from '../../helpers';

export default {
  components: { Modal },
  name: 'FirewallStep2',
  data() {
    return {
      newFirewallData: {
        rules: []
      },
      ruleIndexForEdit: false,
      editRuleModal: false,
      newRule: {
        destination: [],
        source: [],
        ip_destination: [],
        ip_source: [],
        ip_destination_port: [],
        ip_source_port: [],
        ip_protocol: '',
        jump: 'ACCEPT',
        protocol: ''
      },
      newDestination: '',
      newSource: '',
      newIpDestination: '',
      newIpSource: '',
      newPortDestination: '',
      newPortSource: ''
    };
  },
  watch: {
    ruleProtocol() {
      if (!this.newRule.protocol) {
        this.newRule.ip_protocol = '';
      }
    }
  },
  computed: {
    ruleProtocol() {
      return this.newRule.protocol;
    }
  },
  methods: {
    addValue(type, exclamation) {
      if (type === 'destination') {
        this.$validator.validateAll({ destination: this.newDestination }).then((result) => {
          if (result) {
            if (!exclamation) {
              this.newRule.destination.push(this.newDestination);
            } else {
              this.newRule.destination.push(`! ${this.newDestination}`);
            }
            this.newDestination = '';
            this.$validator.reset();
          }
        });
      }
      if (type === 'source') {
        this.$validator.validateAll({ source: this.newSource }).then((result) => {
          if (result) {
            if (!exclamation) {
              this.newRule.source.push(this.newSource);
            } else {
              this.newRule.source.push(`! ${this.newSource}`);
            }
            this.newSource = '';
            this.$validator.reset();
          }
        });
      }
      if (type === 'ip_destination') {
        this.$validator.validateAll({ 'ip-destination': this.newIpDestination }).then((result) => {
          if (result) {
            if (!exclamation) {
              this.newRule.ip_destination.push(this.newIpDestination);
            } else {
              this.newRule.ip_destination.push(`! ${this.newIpDestination}`);
            }
            this.newIpDestination = '';
            this.$validator.reset();
          }
        });
      }
      if (type === 'ip_source') {
        this.$validator.validateAll({ 'ip-source': this.newIpSource }).then((result) => {
          if (result) {
            if (!exclamation) {
              this.newRule.ip_source.push(this.newIpSource);
            } else {
              this.newRule.ip_source.push(`! ${this.newIpSource}`);
            }
            this.newIpSource = '';
            this.$validator.reset();
          }
        });
      }
      if (type === 'ip_destination_port') {
        this.$validator.validateAll({ 'port-destination': this.newPortDestination }).then((result) => {
          if (result) {
            if (!exclamation) {
              this.newRule.ip_destination_port.push(this.newPortDestination);
            } else {
              this.newRule.ip_destination_port.push(`! ${this.newPortDestination}`);
            }
            this.newPortDestination = '';
            this.$validator.reset();
          }
        });
      }
      if (type === 'ip_source_port') {
        this.$validator.validateAll({ 'port-source': this.newPortSource }).then((result) => {
          if (result) {
            if (!exclamation) {
              this.newRule.ip_source_port.push(this.newPortSource);
            } else {
              this.newRule.ip_source_port.push(`! ${this.newPortSource}`);
            }
            this.newPortSource = '';
            this.$validator.reset();
          }
        });
      }
    },
    clearFields() {
      this.newDestination = '';
      this.newSource = '';
      this.newIpDestination = '';
      this.newIpSource = '';
      this.newPortDestination = '';
      this.newPortSource = '';
      this.newRule = {
        destination: [],
        source: [],
        ip_destination: [],
        ip_source: [],
        ip_destination_port: [],
        ip_source_port: [],
        ip_protocol: '',
        jump: 'ACCEPT',
        protocol: ''
      };
    },
    pushNewRule() {
      if (this.newDestination) {
        this.newRule.destination.push(this.newDestination);
      }
      if (this.newSource) {
        this.newRule.source.push(this.newSource);
      }
      if (this.newIpDestination) {
        this.newRule.ip_destination.push(this.newIpDestination);
      }
      if (this.newIpSource) {
        this.newRule.ip_source.push(this.newIpSource);
      }
      if (this.newPortDestination) {
        this.newRule.ip_destination_port.push(this.newPortDestination);
      }
      if (this.newPortSource) {
        this.newRule.ip_source_port.push(this.newPortSource);
      }

      if ((this.ruleIndexForEdit || this.ruleIndexForEdit === 0) && this.newFirewallData.rules[this.ruleIndexForEdit]) {
        this.newFirewallData.rules[this.ruleIndexForEdit] = JSON.parse(JSON.stringify(this.newRule));
      } else {
        this.newFirewallData.rules.push(JSON.parse(JSON.stringify(this.newRule)));
      }
      this.editRuleModal = false;
      this.ruleIndexForEdit = false;
      this.clearFields();
    },
    deleteRule() {
      this.editRuleModal = false;
      this.newFirewallData.rules.splice(this.ruleIndexForEdit, 1);
      this.ruleIndexForEdit = false;
      this.clearFields();
    },
    removeValue(e) {
      const selectedNode = e.target.closest('[data-id]');
      const { id } = selectedNode.dataset;
      const { type } = selectedNode.dataset;
      if (type === 'destination') {
        this.newRule.destination.splice(id, 1);
      }
      if (type === 'source') {
        this.newRule.source.splice(id, 1);
      }
      if (type === 'ip_destination') {
        this.newRule.ip_destination.splice(id, 1);
      }
      if (type === 'ip_source') {
        this.newRule.ip_source.splice(id, 1);
      }
      if (type === 'ip_destination_port') {
        this.newRule.ip_destination_port.splice(id, 1);
      }
      if (type === 'ip_source_port') {
        this.newRule.ip_source_port.splice(id, 1);
      }
    },
    cancelRule() {
      this.editRuleModal = false;
      this.ruleIndexForEdit = false;
      this.clearFields();
    },
    toggleEdit(e) {
      const selectedNode = e.target.closest('[data-index]');
      const index = parseInt(selectedNode.dataset.index);
      if (this.ruleIndexForEdit === index) {
        this.ruleIndexForEdit = false;
        this.newRule = {
          destination: [],
          source: [],
          ip_destination: [],
          ip_source: [],
          portDestination: [],
          ip_source_port: [],
          ip_protocol: '',
          jump: 'ACCEPT',
          protocol: ''
        };
        return;
      }
      this.ruleIndexForEdit = index;
      this.newRule = JSON.parse(JSON.stringify(this.newFirewallData.rules[index]));
      this.editRuleModal = true;
    },
    setNewFirewallData() {
      this.$validator.validateAll({}).then(
        (result) => {
          if (result) {
            this.$store.commit('setNewFirewallData', this.newFirewallData);
            this.$parent.nextStep();
          }
        },
        () => {
          console.warn('Validation failed');
        }
      );
    }
  },
  created() {
    this.newFirewallData = JSON.parse(JSON.stringify(this.$store.state.newFirewallData));
  }
};
</script>
<style lang="scss">
.rules {
  display: flex;
  flex-wrap: wrap;
}

.rules .card {
  width: 48%;
  margin-right: 1.5rem;
  cursor: pointer;
  transition: background-color 0.5s;
}

.rules .card.border-success:hover {
  background-color: rgb(232, 245, 233);
}
.rules .card.border-danger:hover {
  background-color: rgb(251, 233, 231);
}
.rules .card.border-secondary:hover {
  background-color: rgb(245, 245, 245);
}

.rules .card:nth-child(2n) {
  margin-right: 0rem;
}

.rule-value {
  display: block;
}

.card.border-secondary .card-body .rule-title {
  text-align: center;
  margin-bottom: 40px;
}

.text-white .card-body .text-muted {
  color: #eee !important;
}
</style>
